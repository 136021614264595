import { computed, Ref } from 'vue'
import { Invoice } from '@@/types'
import { cleanJoin } from '@/utils'

export default (invoice: Ref<Invoice>) =>
  computed(() => detailsContext(invoice.value))

const detailsContext = (invoice: Invoice) => {
  return {
    sales_name:
      (invoice.salesContact &&
        cleanJoin([
          invoice.salesContact?.firstName,
          invoice.salesContact?.middleName,
          invoice.salesContact?.lastName,
          invoice.salesContact?.pronouns && invoice.templateOverrides?.showContactPronouns ? `(${invoice.salesContact?.pronouns})` : '',
        ])) ??
      '',
    sales_email: invoice.salesContact?.email ?? '',
    sales_phone: invoice.salesContact?.phoneHome ?? '',
  }
}