import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { InvoiceTemplate } from '@@/types'
import { TransactionTemplateFragment } from '.'
import { deepOmit } from '@/utils'

// TODO: make deepOmit act as middleware on the client

export function empty(
  override: Partial<InvoiceTemplate> = {}
): InvoiceTemplate {
  return {
    id: uuid(),
    name: '',
    baseTemplateId: null,
    additionalHeading: '',
    invoiceNumberLabel: '',
    dateLabel: '',
    dueDateLabel: '',
    billingAddressLabel: '',
    shippingAddressLabel: '',
    subtotalLabel: '',
    taxLabel: '',
    shippingLabel: '',
    totalLabel: '',
    remainingBalanceLabel: '',
    headerUrl: '',
    headerAlignment: 'left',
    footerUrl: '',
    footerAlignment: 'left',
    itemCaption: '',
    showCurrencyCode: false,
    showCurrencySymbol: true,
    showPayments: false,
    showDiscountTotal: false,
    hideLineItemTax: false,
    useFullDate: false,
    showDueDate: false,
    showContactPronouns: false,
    additionalDetailsOwnPage: false,
    additionalDetailsFontSize: '',
    additionalDetails: [],
    fontUrl: '',
    fontFamily: '',
    ...override,
  }
}

const useAll =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<InvoiceTemplate[]> => {
    const { data } = await client.query({
      query: gql`
        query {
          transaction_template(
            where: { deleted_at: { _is_null: true } }
            order_by: { name: asc }
          ) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
    })

    return camelcaseKeys(data.transaction_template, { deep: true })
  }

const useFilterCustom =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<InvoiceTemplate[]> => {
    const { data } = await client.query({
      query: gql`
        query {
          transaction_template(
            where: {
              base_template_id: { _is_null: false }
              deleted_at: { _is_null: true }
            }
            order_by: { name: asc }
          ) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
    })

    return camelcaseKeys(data.transaction_template, { deep: true })
  }

const useGet =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (id: string): Promise<InvoiceTemplate> => {
    const { data } = await client.query({
      query: gql`
        query GetTemplate($id: uuid!) {
          transaction_template_by_pk(id: $id) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
      variables: {
        id,
      },
    })

    return camelcaseKeys(data.transaction_template_by_pk, { deep: true })
  }

const useCreate =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (template: InvoiceTemplate): Promise<InvoiceTemplate> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, ...input } = template

    const { data } = await client.mutate({
      mutation: gql`
        mutation CreateTemplate($input: transaction_template_insert_input!) {
          insert_transaction_template_one(object: $input) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
      variables: {
        input: deepOmit(snakecaseKeys(input), ['__typename', 'typename']),
      },
    })

    return camelcaseKeys(data.insert_transaction_template_one, { deep: true })
  }

const useUpdate =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (id: string, template: InvoiceTemplate) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, ...input } = template
    template.additionalDetails?.forEach((detail) => {
      detail.content = detail.content.replace(/\0/g, '')
    })

    const { data } = await client.mutate({
      mutation: gql`
        mutation UpdateTemplate(
          $id: uuid!
          $input: transaction_template_set_input
        ) {
          update_transaction_template_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
      variables: {
        id,
        input: deepOmit(snakecaseKeys(input), ['__typename', 'typename']),
      },
    })

    return camelcaseKeys(data.update_transaction_template_by_pk, { deep: true })
  }

const useRemove =
  (client: ApolloClient<NormalizedCacheObject>) => async (id: string) => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation DeleteTemplate($id: uuid!, $deletedAt: date) {
          update_transaction_template_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: $deletedAt }
          ) {
            ...TransactionTemplateFragment
          }
        }

        ${TransactionTemplateFragment}
      `,
      variables: {
        id,
        deletedAt: new Date(),
      },
    })

    return camelcaseKeys(data.update_transaction_template_by_pk, { deep: true })
  }

export const useTemplateModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    all: useAll(client),
    filterCustom: useFilterCustom(client),
    get: useGet(client),
    create: useCreate(client),
    update: useUpdate(client),
    remove: useRemove(client),
  }
}
