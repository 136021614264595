export { client } from './clients'
export {
  useOrganizationModel,
  empty as emptyOrganization,
} from './organization'
export { useAccountModel, empty as emptyAccount } from './account'
export { useTemplateModel, empty as emptyTemplate } from './template'
export { useInvoiceModel, empty as emptyInvoice } from './invoice'
export { useAddressModel, empty as emptyAddress } from './address'
export { useContactModel, empty as emptyContact } from './contact'
export { useLineItemModel, empty as emptyLineItem } from './lineitem'
export { /* useTaxRateModel ,*/ empty as emptyTaxRate } from './taxrate'
export { usePaymentModel, empty as emptyPayment } from './payment'
export { useNoteModel, empty as emptyNote } from './note'
export { useCurrencyModel, empty as emptyCurrency } from './currency'
export { useCustomFieldModel } from './customfield'
export { useFeatureFlagModel } from './featureflag'
export { useOfferModel } from './offer'
export { useEconomicNexusModel } from './economicnexus'
export { useQuickbooksModel } from './quickbooks'
export { useWorkflowModel, empty as emptyWorkflow } from './workflow'
export { useTagModel } from './tag'
export { useExpenseModel } from './expense'
export { usePayableModel } from './payable'
export { useActivityModel } from './activity'

import { gql } from '@apollo/client'

export const TransactionTemplateFragment = gql`
  fragment TransactionTemplateFragment on transaction_template {
    id
    name
    base_template_id
    additional_heading
    invoice_number_label
    date_label
    due_date_label
    billing_address_label
    shipping_address_label
    subtotal_label
    tax_label
    shipping_label
    total_label
    remaining_balance_label
    header_url
    uncropped_header_url
    header_alignment
    header_size
    footer_url
    uncropped_footer_url
    footer_alignment
    item_caption
    show_currency_code
    show_currency_symbol
    show_payments
    show_discount_total
    hide_line_item_tax
    use_full_date
    show_due_date
    show_contact_pronouns
    additional_details_own_page
    additional_details_font_size
    is_default
    additional_details
    layout_filename
    header_layout_filename
    footer_layout_filename
    font_family
    font_url
    transaction_fee_label
    default_currency
  }
`

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    line1
    line2
    unit
    streetNumber
    streetName
    directionSuffix
    locality
    region
    country
    postalCode
  }
`

export const SaleAddressFragment = gql`
  fragment SaleAddressFragment on organization_sale_addresses {
    id
    name
    label
    line_1
    line_2
    locality
    region
    country
    postal_code
    is_default
  }
`

export const ArtistFragment = gql`
  fragment ArtistFragment on artist {
    id
    birth_year
    death_year
  }
`

export const InventoryMiscFragment = gql`
  fragment InventoryMiscFragment on inventory_misc {
    id
    period
    series
  }
`

export const InventoryFragment = gql`
  fragment InventoryFragment on inventory {
    id
    artist
    artists {
      artist {
        ...ArtistFragment
      }
    }
    title
    title_2
    year
    description
    catalog_number
    edition
    consignor
    size_text
    size_text_metric
    framed_size
    framed_size_metric
    location
    medium
    price
    price_currency
    vat_status
    additional_notes
    status
    invoice_status
    url
    resource_url
    inventory_misc {
      ...InventoryMiscFragment
    }
    artwork_custom_fields {
      id
      organization_custom_field {
        id
        position
        attributes
        created_at
        updated_at
        archived_at
        deleted_at
      }
      field_id
      value
      attributes
      created_at
      updated_at
    }
    inventory_x_contacts(
      where: { status: { _eq: "sold" } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      ...InventoryXContactFragment
    }
  }
`

export const WorkflowFragment = gql`
  fragment WorkflowFragment on workflows {
    id
    name
    stages {
      id
      name
      meta
      order
    }
    transitions {
      id
      name
      from_workflow_stage_id
      to_workflow_stage_id
    }
  }
`

export const OrganizationFragment = gql`
  fragment OrganizationFragment on organization {
    id
    login_name
    invoice_workflow_id
    stripe_account_status
    organization_setting {
      payments_base_url
      payment_providers
    }
    json_settings {
      setting
    }
  }
`

export const CrmContactFragment = gql`
  fragment CrmContactFragment on contact {
    id
    first_name
    last_name
    email
    company
    phone_home
    phone_mobile
    phone_office
    phone_other
    primary_address2
    primary_city
    primary_state
    primary_country
    primary_street
    primary_zip
    image_url
    honorific
    extra
    kyc_verified
  }
`

export const InventoryXContactFragment = gql`
  fragment InventoryXContactFragment on inventory_x_contact {
    contact {
      ...CrmContactFragment
    }
    status
    inventory_id
    price
    currency
  }
`

export const excludeCase = [
  '__typename',

  // captions
  'artist year',
  'inventory #',
  'dimensions (metric)',
  'framed dimensions',
  'framed dimensions (metric)',
  'title (line 2)',
  'vat status',
  'additional notes',
  'price_numerical',
]
