import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import {
  emptyAddress,
  emptyContact,
  emptyTemplate,
  // WorkflowFragment,
  // useAccountModel,
  // useFeatureFlagModel,
  CrmContactFragment,
  InventoryXContactFragment,
} from '@/models'
import {
  CrmInvoice,
  // CrmPermission,
  // Inventory,
  Invoice,
  PaginatedResult,
} from '@@/types'
import {
  calculatePagination,
  transformCrmInvoice /* transformInvoice */,
} from '@/utils'
import {
  ArtistFragment,
  InventoryFragment,
  InventoryMiscFragment,
  SaleAddressFragment,
  TransactionTemplateFragment,
} from '.'

// const CrmPermissionFragment = gql`
//   fragment CrmPermissionFragment on permission {
//     id
//     access
//     group_id
//     transaction_id
//   }
// `

const TransactionItemFragment = gql`
  fragment TransactionItemFragment on transaction_item {
    id
    description
    price
    currency
    discount
    discount_percent
    image_url
    meta
    position
    is_taxed
    special_case
    inventory {
      ...InventoryFragment
    }
  }
`

const crmInvoiceFragment = gql`
  fragment crmInvoiceFragment on transaction {
    id
    uuid

    group_ids

    transaction_number
    locale
    title
    created_by
    updated_by
    date_out
    due_date
    currency
    template {
      ...TransactionTemplateFragment
    }
    status # sent or unsent
    paid_status # paid, partially paid, or unpaid
    sent_at
    archived
    updated_at

    sale_address {
      ...SaleAddressFragment
    }

    sales_first_name
    sales_middle_name
    sales_last_name
    sales_pronouns
    sales_email
    sales_phone
    sales_image_url
    sales_contact_id

    shipped_street
    shipped_street2
    shipped_city
    shipped_state
    shipped_country
    shipped_zip

    state_for_nexus

    # Billing Address
    address_street
    address_street2
    address_city
    address_state
    address_country
    address_zip

    attn
    shipping_attn
    contact_id
    first_name
    last_name
    billing_middle_name
    billing_pronouns
    billing_email
    billing_phone
    billing_image_url
    billing_honorific

    artist_contact_id
    artist_contact_first_name
    artist_contact_middle_name
    artist_contact_last_name
    artist_contact_pronouns
    artist_contact_email
    artist_contact_phone
    artist_contact_image_url

    shipped_contact_id
    shipping_first_name
    shipping_middle_name
    shipping_last_name
    shipping_pronouns
    shipping_email
    shipping_phone
    shipping_image_url
    shipping_honorific

    shipping_fee
    tax_shipping
    tax_amount
    tax_percentage
    tax_percentage_source
    tax_key

    # total for classic invoices
    total_price

    deposit_amount
    deposit_percentage

    additional_heading
    invoice_number_label
    date_label
    due_date_label
    billing_address_label
    shipping_address_label
    subtotal_label
    tax_label
    shipping_label
    total_label
    remaining_balance_label
    header_url
    header_alignment
    header_size
    footer_url
    footer_alignment
    item_caption
    show_currency_code
    show_currency_symbol
    show_payments
    show_discount_total
    hide_line_item_tax
    use_full_date
    show_due_date
    show_contact_pronouns
    additional_details_own_page
    additional_details_font_size

    notes(
      where: { deleted_at: { _is_null: true }, deleted: { _eq: false } }
      order_by: { created_at: desc }
    ) {
      id
      content
      created_by_id
      created_at
      updated_at
    }

    payment_method
    additional_details
    transaction_items(
      where: { deleted_at: { _is_null: true } }
      order_by: { position: asc }
    ) {
      ...TransactionItemFragment
    }
    payment_history(
      where: { deleted_at: { _is_null: true }, deleted: { _eq: false } }
      order_by: { date: desc }
    ) {
      id
      amount
      description
      date
    }
    passcode
    download_link_color
    disable_artwork_item_price
    exclude_discounts_from_subtotal
    physical_sale
    qb_last_sync_date

    offer {
      clickthrough_count
    }

    workflow_stage {
      id
      name
      meta
    }

    workflow_id
    workflow_stage_id

    transaction_fee_type

    transaction_x_tags {
      tag {
        id
        tag
        source
      }
    }

    contact_kyc_verified: contact {
      kyc_verified
    }

    voided_at

    tracking_link
    view_count
    
    user_id
    group_ids
    
    cover_image_url
    paid_amount
  }
`

// DEPRECATED: Use CrmInvoiceFragmentMinimal.ts from @/fragments/invoice instead
const crmInvoiceFragmentMinimal = gql`
  fragment crmInvoiceFragmentMinimal on transaction {
    # key
    id

    # basic info displayed
    transaction_number
    title
    date_out
    due_date
    created_by
    archived

    # billing contact name
    first_name
    last_name

    # determines V2 vs classic invoice
    template_id

    # total for classic invoice
    total_price

    # data needed to calculate invoice total
    currency
    shipping_fee
    tax_shipping
    tax_amount
    tax_percentage
    transaction_items(
      where: { deleted_at: { _is_null: true } }
      order_by: { position: asc }
    ) {
      ...TransactionItemFragment
    } # thumbnail comes from first item
    # data needed for status badge
    status
    paid_status
    sent_at
    payment_history(
      where: { deleted_at: { _is_null: true }, deleted: { _eq: false } }
      order_by: { date: desc }
    ) {
      id
      amount
      description
      date
    }

    # stage name displayed as badge
    workflow_stage {
      name
    }

    # QuickBooks last sync date
    qb_last_sync_date

    offer {
      clickthrough_count
    }

    # data needed for QuickBooks sync
    contact_id
    billing_email
    shipped_street
    shipped_street2
    shipped_city
    shipped_state
    shipped_country
    shipped_zip

    disable_artwork_item_price

    transaction_x_tags {
      tag {
        id
        tag
        source
      }
    }

    contact_kyc_verified: contact {
      kyc_verified
    }

    voided_at

    tracking_link
    view_count
    
    user_id
    group_ids
  }
`

export function empty(override: Partial<Invoice> = {}): Invoice {
  return {
    groupIds: [],
    shareId: uuid(),
    invoiceNumber: '000001',
    locale: 'en-US',
    title: `New Invoice ${new Date().toLocaleDateString()}`,
    createdBy: '',
    updatedBy: '',
    date: new Date(),
    dueDate: undefined,
    currency: 'USD',
    template: emptyTemplate(),
    status: 'draft',
    archived: false,
    savedAt: new Date(),
    saleAddress: emptyAddress(),
    customerContact: emptyContact(),
    artistContact: emptyContact(),
    shippingContact: emptyContact(),
    shippingAddress: emptyAddress(),
    billingAddress: emptyAddress(),
    shipping: null,
    paymentProviderId: 'none',
    details: [],
    lineItems: [],
    notes: [],
    payments: [],
    passcode: '',
    taxPercentage: 0,
    taxPercentageSource: 'auto',
    totalPrice: 0,
    openCount: 0,
    transactionFeeType: 'amount',
    tags: [],
    voidedAt: null,
    trackingLink: null,
    viewCount: 0,
    userId: null,
    coverImageUrl: null,
    private: false,
    ...override,
  }
}

export const excludeCase = [
  '__typename',

  // captions
  'artist year',
  'inventory #',
  'dimensions (metric)',
  'framed dimensions',
  'framed dimensions (metric)',
  'title (line 2)',
  'vat status',
  'additional notes',
  'price_numerical',
]

// DEPRECATED: Use allInvoices.ts from @/queries/invoice instead
const useAll =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    where = {},
    limit = 20,
    offset = 0,
    orderBy = {},
    archived = false,
    voided = false,
  }: {
    where?: Record<string, unknown>
    limit?: number
    offset?: number
    orderBy?: Record<string, unknown>
    archived?: boolean
    voided?: boolean
  } = {}): Promise<PaginatedResult<Invoice>> => {
    // merge with defaults
    const whereMerged: Record<string, unknown> = {
      archived: { _eq: false },
      voided_at: { _is_null: true },
      deleted: { _eq: false },
      type: { _eq: 'invoice' },
      ...(orderBy.due_date ? { due_date: { _is_null: false } } : {}),
      ...where,
    }

    // Workaround to ensure that archived and voided_at filters are added to the OR clause
    if (archived) {
      if (!whereMerged['_or']) {
        whereMerged['_or'] = []
      }
      (whereMerged['_or'] as Record<string, unknown>[]).push({ archived: { _eq: true } })
      delete whereMerged.archived
    }
    if (voided) {
      if (!whereMerged['_or']) {
        whereMerged['_or'] = []
      }
      (whereMerged['_or'] as Record<string, unknown>[]).push({ voided_at: { _is_null: false } })
      delete whereMerged.voided_at
    }

    const { data } = camelcaseKeys(
      await client.query({
        query: gql`
          query allInvoices(
            $where: transaction_bool_exp
            $limit: Int!
            $offset: Int!
            $orderBy: [transaction_order_by!]
          ) {
            transaction(
              limit: $limit
              offset: $offset
              where: $where
              order_by: $orderBy
            ) {
              ...crmInvoiceFragmentMinimal
            }
            transaction_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }

          ${TransactionTemplateFragment}
          ${ArtistFragment}
          ${CrmContactFragment}
          ${InventoryMiscFragment}
          ${InventoryXContactFragment}
          ${InventoryFragment}
          ${TransactionItemFragment}
          ${SaleAddressFragment}
          ${crmInvoiceFragmentMinimal}
        `,

        variables: {
          where: whereMerged,
          limit,
          offset,
          orderBy,
        },
      }),
      { deep: true, exclude: excludeCase }
    )

    const total = data.transactionAggregate.aggregate.count

    return [
      data.transaction.map((crmInvoice: CrmInvoice) => {
        // Temporary hacky data restructure. The useAll function will soon be deprecated
        const { transactionXTags, ...crmInvoiceFields } = crmInvoice
        return transformCrmInvoice({
          ...crmInvoiceFields,
          contactKycVerified:
            (crmInvoiceFields.contactKycVerified as any)?.kycVerified ?? false,
          tags: (transactionXTags || []).map((txt) => txt.tag),
        })
      }),
      calculatePagination({ offset, limit, total }),
    ]
  }

// DEPRECATED: Use getInvoice.ts from @/queries/invoice instead
// const useGet =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (id: string): Promise<Invoice> => {
//     const featureFlagModel = useFeatureFlagModel(client)
//     const workflowsEnabled = await featureFlagModel.has('workflows')

//     const { data } = await client.query({
//       query: gql`
//         query getInvoice($id: Int!) {
//           transaction_by_pk(id: $id) {
//             ...crmInvoiceFragment
//             ${
//               workflowsEnabled
//                 ? `workflow {
//                     ...WorkflowFragment
//                   }`
//                 : ''
//             }
//           }
//         }
//         ${OfferFragment}
//         ${TransactionTemplateFragment}
//         ${ArtistFragment}
//         ${CrmContactFragment}
//         ${InventoryMiscFragment}
//         ${InventoryXContactFragment}
//         ${InventoryFragment}
//         ${TransactionItemFragment}
//         ${SaleAddressFragment}
//         ${crmInvoiceFragment}
//         ${WorkflowFragment}
//       `,

//       variables: {
//         id: parseInt(id),
//       },
//     })

//     return transformCrmInvoice(
//       camelcaseKeys(data.transaction_by_pk, {
//         deep: true,
//         exclude: excludeCase,
//       })
//     )
//   }

// DEPRECATED: Use create function from @/mutations instead
// const useCreate =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (invoice: Invoice, groupIds: string[]): Promise<Invoice> => {
//     const { data } = await client.mutate({
//       mutation: gql`
//         mutation CreateInvoice($invoice: transaction_insert_input!) {
//           insert_transaction_one(object: $invoice) {
//             ...crmInvoiceFragment
//           }
//         }

//         ${OfferFragment}
//         ${TransactionTemplateFragment}
//         ${ArtistFragment}
//         ${CrmContactFragment}
//         ${InventoryMiscFragment}
//         ${InventoryXContactFragment}
//         ${InventoryFragment}
//         ${TransactionItemFragment}
//         ${SaleAddressFragment}
//         ${crmInvoiceFragment}
//       `,

//       variables: {
//         invoice: deepOmit(
//           snakecaseKeys(transformInvoice(invoice), { exclude: excludeCase }),
//           '__typename'
//         ),
//       },
//     })

//     const permissions = groupIds.map((id) => ({
//       access: '*',
//       groupId: parseInt(id),
//       transactionId: data.insert_transaction_one.id,
//     }))

//     const createBatchInvoicePermission = useCreateBatchInvoicePermission(client)
//     await createBatchInvoicePermission(permissions)

//     return transformCrmInvoice(
//       camelcaseKeys(data.insert_transaction_one, {
//         deep: true,
//         exclude: excludeCase,
//       })
//     )
//   }

// const useCreateBatchInvoicePermission =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (permissions: Partial<CrmPermission>[]): Promise<CrmPermission[]> => {
//     const { data } = await client.mutate({
//       mutation: gql`
//         mutation CreateInvoicePermission(
//           $permissions: [permission_insert_input!]!
//         ) {
//           insert_permission(objects: $permissions) {
//             returning {
//               ...CrmPermissionFragment
//             }
//           }
//         }

//         ${CrmPermissionFragment}
//       `,

//       variables: {
//         permissions: snakecaseKeys(permissions),
//       },
//     })

//     return camelcaseKeys(data.insert_permission, {
//       deep: true,
//       exclude: excludeCase,
//     })
//   }

// DEPRECATED: Use update function from @/mutations instead
// const useUpdate =
//   (client: ApolloClient<NormalizedCacheObject>) => async (invoice: Invoice) => {
//     const accountModel = useAccountModel(client)
//     const account = await accountModel.get()

//     if (!invoice.id) return
//     const getCurrentCrmInvoiceResponse = await client.query({
//       query: gql`
//         query getInvoice($id: Int!) {
//           transaction_by_pk(id: $id) {
//             ...crmInvoiceFragment
//           }
//         }

//         ${OfferFragment}
//         ${TransactionTemplateFragment}
//         ${ArtistFragment}
//         ${CrmContactFragment}
//         ${InventoryMiscFragment}
//         ${InventoryXContactFragment}
//         ${InventoryFragment}
//         ${TransactionItemFragment}
//         ${SaleAddressFragment}
//         ${crmInvoiceFragment}
//       `,

//       variables: {
//         id: parseInt(invoice.id),
//       },
//     })

//     const oldInvoice: Invoice = transformCrmInvoice(
//       camelcaseKeys(getCurrentCrmInvoiceResponse.data.transaction_by_pk, {
//         deep: true,
//         exclude: excludeCase,
//       })
//     )

//     // These need to be saved separately with on_conflict set to invoice_row_inventory_id_invoice_id_unique
//     const newInventoryItems =
//       invoice.lineItems
//         ?.map(({ position, ...item }, index) => {
//           return {
//             ...item,
//             position: position !== null ? index : null,
//           }
//         })
//         .filter((item) => !/^\d+$/.test(item.id ?? '') && item.inventory)
//         .map(
//           ({
//             id,
//             discountAmount,
//             price,
//             taxAmount: _taxAmount,
//             discountedPrice: _discountedPrice,
//             position,
//             inventory,
//             ...transactionItem
//           }) => {
//             return {
//               ...transactionItem,
//               ...(id && /^\d+$/.test(id) && { id: parseInt(id) }),
//               transactionId: invoice.id,
//               inventoryId: inventory?.id,
//               price: toCents(price),
//               discount: toCents(price > 0 ? discountAmount ?? 0 : 0).toString(),
//               position,
//               deletedAt: null,
//             }
//           }
//         ) || []

//     const updatedLineItemIds =
//       invoice.lineItems?.filter((item) => item.id).map((item) => item.id) ?? []

//     const oldLineItemIds = oldInvoice.lineItems?.map((item) => item.id) || []
//     const deletedLineItemIds = oldLineItemIds.filter(
//       (itemId) => !updatedLineItemIds.includes(itemId?.toString())
//     )
//     const deletedLineItems =
//       oldInvoice.lineItems
//         ?.filter((item) => deletedLineItemIds.includes(item.id))
//         .map((item) => {
//           return { ...item, deletedAt: new Date() }
//         }) || []

//     const updatedNoteIds = invoice.notes
//       ? invoice.notes
//           .filter((item) => item.id)
//           .map((item) => item.id?.toString())
//       : []

//     const oldNoteIds =
//       oldInvoice.notes?.map((item) => item.id?.toString()) || []
//     const deletedNoteIds = oldNoteIds.filter(
//       (itemId) => !updatedNoteIds.includes(itemId?.toString())
//     )

//     const deletedNotes =
//       oldInvoice.notes
//         ?.filter((item) => deletedNoteIds.includes(item.id?.toString()))
//         .map((item) => {
//           return {
//             ...item,
//             deleted: true,
//             deletedAt: new Date(),
//             updatedById: account.id,
//             updatedAt: new Date(),
//           }
//         }) || []

//     const updatedPaymentIds = invoice.payments
//       ? invoice.payments
//           .filter((item) => item.id)
//           .map((item) => item.id?.toString())
//       : []

//     const oldPaymentIds =
//       oldInvoice.payments?.map((item) => item.id?.toString()) || []
//     const deletedPaymentIds = oldPaymentIds.filter(
//       (itemId) => !updatedPaymentIds.includes(itemId?.toString())
//     )
//     const deletedPayments =
//       oldInvoice.payments
//         ?.filter((item) => deletedPaymentIds.includes(item.id?.toString()))
//         .map((item) => {
//           return { ...item, deleted: true, deletedAt: new Date() }
//         }) || []

//     invoice.lineItems = [...(invoice.lineItems ?? []), ...deletedLineItems]
//     invoice.notes = [...(invoice.notes ?? []), ...deletedNotes]
//     invoice.payments = [...(invoice.payments ?? []), ...deletedPayments]

//     invoice.lineItems.forEach(async (item) => {
//       if (item.inventory) {
//         await client.mutate({
//           mutation: gql`
//             mutation SetDiscount($id: Int!, $discount: Int!) {
//               update_inventory(
//                 where: { id: { _eq: $id } }
//                 _set: { discount: $discount }
//               ) {
//                 returning {
//                   discount
//                 }
//               }
//             }
//           `,
//           variables: {
//             id: item.inventory.id,
//             discount: item.discountAmount * 100,
//           },
//         })
//       }
//     })

//     if (newInventoryItems.length) {
//       await client.mutate({
//         mutation: gql`
//           mutation saveInventoryItems(
//             $objects: [transaction_item_insert_input!]!
//           ) {
//             insert_transaction_item(
//               objects: $objects
//               on_conflict: {
//                 constraint: invoice_row_inventory_id_invoice_id_unique
//                 update_columns: [
//                   description
//                   price
//                   currency
//                   discount
//                   image_url
//                   meta
//                   position
//                   deleted_at
//                 ]
//               }
//             ) {
//               returning {
//                 ...TransactionItemFragment
//               }
//             }
//           }

//           ${ArtistFragment}
//           ${CrmContactFragment}
//           ${InventoryMiscFragment}
//           ${InventoryXContactFragment}
//           ${InventoryFragment}
//           ${TransactionItemFragment}
//         `,

//         variables: {
//           objects: deepOmit(
//             snakecaseKeys(newInventoryItems, { exclude: excludeCase }),
//             ['__typename']
//           ),
//         },
//       })
//     }

//     const updatedCrmInvoice = transformInvoice(invoice, true)

//     const inputCrmInvoice = deepOmit(
//       {
//         ...snakecaseKeys(updatedCrmInvoice, { exclude: excludeCase }),
//       },
//       ['__typename', 'typename']
//     )

//     const fields = Object.keys(invoice).filter((field) =>
//       !['id', 'transaction_items', 'payment_history', 'notes'].includes(field)
//     )

//     const { data } = await client.mutate({
//       mutation: gql`
//         mutation UpsertTransaction(
//           $input: transaction_insert_input!
//           $fields: [transaction_update_column!]!
//         ) {
//           insert_transaction_one(
//             object: $input
//             on_conflict: {
//               constraint: transaction_uuid_unique
//               update_columns: $fields
//             }
//           ) {
//             ...crmInvoiceFragment
//           }
//         }

//         ${OfferFragment}
//         ${TransactionTemplateFragment}
//         ${ArtistFragment}
//         ${CrmContactFragment}
//         ${InventoryMiscFragment}
//         ${InventoryXContactFragment}
//         ${InventoryFragment}
//         ${TransactionItemFragment}
//         ${SaleAddressFragment}
//         ${crmInvoiceFragment}
//       `,
//       variables: {
//         input: inputCrmInvoice,
//         fields,
//       },
//     })

//     // update invoice_status of inventory line items
//     const inventoryItems = (invoice.lineItems
//       ?.map((item) => item.inventory)
//       .filter(Boolean) ?? []) as Inventory[]

//     if (
//       oldInvoice.status !== invoice.status ||
//       oldInvoice.paidStatus !== invoice.paidStatus ||
//       newInventoryItems.length
//     ) {
//       let invoiceStatus = ''
//       if (invoice.status === 'draft') {
//         invoiceStatus = 'Invoice Drafted'
//       } else if (invoice.status === 'sent') {
//         invoiceStatus = 'Invoice Outstanding'
//       } else if (invoice.paidStatus === 'paid') {
//         invoiceStatus = 'Invoice Completed'
//       }

//       inventoryItems.forEach((work) => {
//         if (work.invoiceStatus === invoiceStatus) return

//         client.mutate({
//           mutation: gql`
//             mutation updateInventoryInvoiceStatus(
//               $id: Int!
//               $invoiceStatus: String
//             ) {
//               update_inventory(
//                 where: { id: { _eq: $id } }
//                 _set: { invoice_status: $invoiceStatus }
//               ) {
//                 returning {
//                   id
//                   invoice_status
//                 }
//               }
//             }
//           `,
//           variables: {
//             id: work.id,
//             invoiceStatus,
//           },
//         })
//       })
//     }
//     deletedLineItems.forEach((item) => {
//       if (!item.inventory) return

//       client.mutate({
//         mutation: gql`
//           mutation updateInventoryInvoiceStatus(
//             $id: Int!
//             $invoiceStatus: String
//           ) {
//             update_inventory(
//               where: { id: { _eq: $id } }
//               _set: { invoice_status: null }
//             ) {
//               returning {
//                 id
//                 invoice_status
//               }
//             }
//           }
//         `,
//         variables: {
//           id: item.inventory.id,
//         },
//       })
//     })

//     // if sales contact was updated, then update the inventory assocations
//     if (
//       oldInvoice.artistContact?.id !== invoice.artistContact?.id ||
//       oldInvoice.salesContact?.id !== invoice.salesContact?.id
//     ) {
//       // TODO, gate this as portraits only for now
//       const inventoryIds = invoice.lineItems.map((item) => item.inventory?.id)
//       await client.mutate({
//         mutation: gql`
//           mutation syncSalesAndArtistReps(
//             $inventoryIds: [Int]!
//             $invoiceId: Int!
//           ) {
//             core {
//               syncSalesAndArtistReps(
//                 inventoryIds: $inventoryIds
//                 invoiceId: $invoiceId
//               )
//             }
//           }
//         `,
//         variables: {
//           inventoryIds,
//           invoiceId: invoice?.id,
//         },
//       })
//     }

//     if (newInventoryItems.length > 0) {
//       const inventoryIds = newInventoryItems.map((item) => item.inventoryId)

//       // TODO, gate this as portraits only for now
//       await client.mutate({
//         mutation: gql`
//           mutation syncSalesAndArtistReps(
//             $inventoryIds: [Int]!
//             $invoiceId: Int!
//           ) {
//             core {
//               syncSalesAndArtistReps(
//                 inventoryIds: $inventoryIds
//                 invoiceId: $invoiceId
//               )
//             }
//           }
//         `,
//         variables: {
//           inventoryIds,
//           invoiceId: invoice?.id,
//         },
//       })

//       const getCurrentCrmInvoiceResponse = await client.query({
//         query: gql`
//           query getInvoice($id: Int!) {
//             transaction_by_pk(id: $id) {
//               artist_contact_id
//               artist_contact_first_name
//               artist_contact_last_name
//               artist_contact_email
//               artist_contact_phone
//               artist_contact_image_url
//               sales_first_name
//               sales_last_name
//               sales_email
//               sales_phone
//               sales_image_url
//               sales_contact_id
//             }
//           }
//         `,

//         variables: {
//           id: parseInt(invoice.id),
//         },
//       })

//       data.insert_transaction_one = Object.assign(
//         data.insert_transaction_one,
//         getCurrentCrmInvoiceResponse.data.transaction_by_pk
//       )
//     }
//     return transformCrmInvoice(
//       camelcaseKeys(data.insert_transaction_one, {
//         deep: true,
//         exclude: excludeCase,
//       })
//     )
//   }

// DEPRECATED: Use axios to call the CRM API directly
// const useSet =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (id: number, set: Partial<CrmInvoice>): Promise<Invoice> => {
//     const invoice = set.deleted
//       ? await useGet(client)(id.toString())
//       : undefined

//     const { data } = await client.mutate({
//       mutation: gql`
//         mutation SetInvoice($id: Int!, $set: transaction_set_input!) {
//           update_transaction_by_pk(pk_columns: { id: $id }, _set: $set) {
//             ...crmInvoiceFragment
//           }
//         }

//         ${OfferFragment}
//         ${TransactionTemplateFragment}
//         ${ArtistFragment}
//         ${CrmContactFragment}
//         ${InventoryMiscFragment}
//         ${InventoryXContactFragment}
//         ${InventoryFragment}
//         ${TransactionItemFragment}
//         ${SaleAddressFragment}
//         ${crmInvoiceFragment}
//       `,
//       variables: {
//         id,
//         set: deepOmit(
//           snakecaseKeys(set, { exclude: excludeCase }),
//           '__typename'
//         ),
//       },
//     })

//     // remove invoice status on deleted invoice's inventory line items
//     if (set.deleted && invoice?.lineItems) {
//       invoice.lineItems.forEach((item) => {
//         if (!item.inventory) return

//         client.mutate({
//           mutation: gql`
//             mutation updateInventoryInvoiceStatus(
//               $id: Int!
//               $invoiceStatus: String
//             ) {
//               update_inventory(
//                 where: { id: { _eq: $id } }
//                 _set: { invoice_status: null }
//               ) {
//                 returning {
//                   id
//                   invoice_status
//                 }
//               }
//             }
//           `,
//           variables: {
//             id: item.inventory.id,
//           },
//         })
//       })
//     }

//     return transformCrmInvoice(
//       camelcaseKeys(data.update_transaction_by_pk, {
//         deep: true,
//         exclude: excludeCase,
//       })
//     )
//   }

// DEPRECATED
// const useLatestInvoiceNumber =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (templateId: string, orgIds: number[] = []): Promise<string> => {
//     const { data } = await client.query({
//       query: gql`
//         query latestInvoiceNumber($templateId: uuid!, $orgIds: [Int!]) {
//           transaction(
//             limit: 1
//             order_by: { created_at: desc }
//             where: {
//               type: { _eq: "invoice" }
//               transaction_number: { _is_null: false }
//               template_id: { _eq: $templateId }
//               organization_id: { _in: $orgIds }
//             }
//           ) {
//             created_at
//             transaction_number
//           }
//         }
//       `,
//       variables: {
//         templateId,
//         orgIds,
//       },
//     })

//     return data.transaction.at(0)?.transaction_number ?? 'INV-00000'
//   }

const useSearch =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    where = {},
    orgIds = [],
    limit = 20,
    offset = 0,
    term = '',
    orderBy = { created_at: 'desc' },
  }: {
    where?: Record<string, unknown>
    orgIds?: number[]
    limit?: number
    offset?: number
    term?: string
    orderBy?: Record<string, unknown>
  } = {}): Promise<PaginatedResult<Invoice>> => {
    const whereMerged = {
      deleted: { _eq: false },
      type: { _eq: 'invoice' },
      organization_id: { _in: orgIds },
      ...where,
    }

    const tokens =
      term
        .toLowerCase()
        .replace(/[^\w\s]+/g, '')
        .match(/[^\s]+/g) ?? []
    const search = tokens.map((token) => `${token}:*`).join(' & ')

    const { data } = camelcaseKeys(
      await client.query({
        query: gql`
          query searchTransactions(
            $search: tsquery
            $limit: Int!
            $offset: Int!
            $where: transaction_bool_exp
            $orderBy: [transaction_order_by!]
          ) {
            search_transactions(
              limit: $limit
              offset: $offset
              args: { search: $search }
              where: $where
              order_by: $orderBy
            ) {
              ...crmInvoiceFragment
            }
            search_transactions_aggregate(
              where: $where
              args: { search: $search }
            ) {
              aggregate {
                count
              }
            }
          }

          ${TransactionTemplateFragment}
          ${ArtistFragment}
          ${CrmContactFragment}
          ${InventoryMiscFragment}
          ${InventoryXContactFragment}
          ${InventoryFragment}
          ${TransactionItemFragment}
          ${SaleAddressFragment}
          ${crmInvoiceFragment}
        `,
        variables: {
          search: search,
          limit,
          offset,
          where: whereMerged,
          orderBy: snakecaseKeys(orderBy, { exclude: excludeCase }),
        },
      }),
      { deep: true, exclude: excludeCase }
    )

    const total = data.searchTransactionsAggregate.aggregate.count

    return [
      data.searchTransactions.map((crmInvoice: CrmInvoice) => {
        // Temporary hacky data restructure. The useAll function will soon be deprecated
        const { transactionXTags, ...crmInvoiceFields } = crmInvoice
        return transformCrmInvoice({
          ...crmInvoiceFields,
          contactKycVerified:
            (crmInvoiceFields.contactKycVerified as any)?.kycVerified ?? false,
          tags: (transactionXTags || []).map((txt) => txt.tag),
        })
      }),
      calculatePagination({ offset, limit, total }),
    ]
  }

export const useInvoiceModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    all: useAll(client),
    // get: useGet(client),
    // create: useCreate(client),
    // createBatchInvoicePermission: useCreateBatchInvoicePermission(client),
    // update: useUpdate(client),
    // set: useSet(client),
    search: useSearch(client),
    // latestInvoiceNumber: useLatestInvoiceNumber(client),
  }
}
