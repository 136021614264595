import gql from 'graphql-tag'
import { AdditionalDetailFragment } from './additionalDetail'

export const TransactionTemplateFragment = gql`
  fragment TransactionTemplateFragment on InvoiceTemplate {
    id
    name
    base_template_id
    additional_heading
    invoice_number_label
    date_label
    due_date_label
    billing_address_label
    shipping_address_label
    subtotal_label
    tax_label
    shipping_label
    total_label
    remaining_balance_label
    header_url
    uncropped_header_url
    header_alignment
    header_size
    footer_url
    uncropped_footer_url
    footer_alignment
    item_caption
    show_currency_code
    show_currency_symbol
    show_payments
    show_discount_total
    hide_line_item_tax
    use_full_date
    show_due_date
    show_contact_pronouns
    additional_details_own_page
    additional_details_font_size
    is_default
    additional_details {
      ...AdditionalDetailFragment
    }
    layout_filename
    header_layout_filename
    footer_layout_filename
    font_family
    font_url
    transaction_fee_label
    default_currency
    __typename
  }
  ${AdditionalDetailFragment}
`